<template>
  <div
    class="Stat"
    v-if="stats.overallStats">
    <table>
      <thead>
        <tr>
          <th/>
          <th
            v-for="stat in ['created', 'started', 'completed', 'requisitionSent', 'billable']"
            :key="stat">
            {{ mixWB(`STAT_NAME_${stat.toUpperCase()}`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ mixWB('STAT_TABLE_OVERALL') }}
          </td>
          <td
            v-for="stat in ['created', 'started', 'completed', 'requisitionSent', 'billable']"
            :key="stat">
            {{ stats.overallStats && stats.overallStats[stat] ? stats.overallStats[stat] : 0 }}
          </td>
        </tr>
        <tr
          v-for="(monthStat, monthYear) in monthStats"
          :key="monthYear">
          <td>{{ monthYear }}</td>
          <td
            v-for="stat in ['created', 'started', 'completed', 'requisitionSent', 'billable']"
            :key="stat">
            {{ monthStat && monthStat[stat] ? monthStat[stat] : 0 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'Stat',
  props: {
    stats: {
      type: Object,
      required: true,
    },
    monthsToShow: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    monthStats() {
      if (!this.stats.monthlyStats) {
        return []
      }

      let monthYears = Object.keys(this.stats.monthlyStats).sort().reverse()

      if (this.monthsToShow === 0) {
        return []
      }

      if (this.monthsToShow > 0) {
        monthYears = monthYears.slice(0, this.monthsToShow)
      }

      return monthYears.reduce((carry, monthYear) => {
        carry[monthYear] = this.stats.monthlyStats[monthYear]
        return carry
      }, {})
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Stat
    overflow auto
    table,
    tr,
    td
      border-collapse collapse
    table
      width 100%
    tr
      &:nth-child(odd)
        background-color $color_grey_lightest
    th, td
      font-size 0.875rem
      padding: 2.5px 10px
      text-align center
      vertical-align top
      &:first-child
        text-align left
    th
      color $color_grey
      font-weight bold
    tbody th
      white-space nowrap
</style>
